import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./Navbar";
import Home from "./Home";
import Blog from "./Blog";
import Projects from "./Projects";
import Resume from "./Resume";
import Contact from "./Contact";

export default function App() {
    const paths = ["/blog", "/projects", "/resume", "/contact"];
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/resume" element={<Resume />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/:anythingelse" element={<RouteCatcher paths={paths} />} />
            </Routes>
            <footer className="pt-4 pb-4 text-white text-center">
                <div className="container">
                    <a href="https://github.com/ophirgal/" className="btn fs-2 bi bi-github text-white" target="_blank"></a>
                    <a href="https://www.linkedin.com/in/-ophir-gal/" className="btn fs-2 bi bi-linkedin text-white" target="_blank"></a>
                    <a href="mailto:ophirgal2@gmail.com" className="btn fs-2 bi bi-envelope-fill text-white" target="_blank" data-toggle="tooltip" data-placement="top" title="ophirgal2@gmail.com"></a>
                    <p className="lead pb-0 mb-0">Copyright &copy; 2024 Ophir Gal.<br />All rights reserved.</p>
                </div>
            </footer>
        </Router>
    );
}

function RouteCatcher({ paths }) {
    let path = window.location.pathname
    if (!paths.includes(path)) {
        path = "/"
    }

    return <Navigate to={path} />
}